import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
// import { HTTP } from '@awesome-cordova-plugins/http/ngx';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  url = environment.ApiUrl;
  myToken: any;
  httpOptions: any;
  constructor(private http: HttpClient, 
    // private httpNat: HTTP,
     private platform: Platform,
    // private storage: Storage
    ) {

  }

  postReq(endPoint: any, postData: any, methodApi: any = 'post') {
    return new Promise(resolve => {
      // if (this.platform.is('cordova')) {
      //   let hednew = {
      //     'Accept': 'application/json',
      //     'Content-Type': 'application/json',
      //   }

      //   this.httpNat.sendRequest(this.url + endPoint,
      //     {
      //       method: methodApi,
      //       data: postData,
      //       headers: hednew,
      //       timeout: 5000
      //     }
      //   ).then(response => {
      //     let d = JSON.parse(response.data);
      //     resolve(d);
      //     console.log(d);
      //     return response.data
      //   }).catch((err) => {
      //     console.log(err);
      //   })

      // } else 
      
      {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, get, OPTIONS, PUT',
          })
        }
        if (methodApi == 'post') {
          return this.http.post(this.url + endPoint, postData, this.httpOptions).subscribe((data: any) => {
            resolve(data);
          }, (err) => {
            console.log(err);
          });
        } else {
          // for get request
          return this.http.get(this.url + endPoint, this.httpOptions).subscribe((data: any) => {
            resolve(data);
          }, (err) => {
            console.log(err);
          });

        }
      }
    });
  }


  getVenderVenue(postData: any) {
    return this.postReq('homeData', postData,'get');
  }

  getStates(postData: any) {
    return this.postReq('stateList/'+postData.country_id, postData,'get');
  }

  getCity(postData: any) {
    return this.postReq('cityList/'+postData.state_id, postData,'get');
  }

  getBlog(postData: any) {
    return this.postReq('blogs', postData,'get');
  }

  getBlogDetail(postData: any) {
    return this.postReq('blogDetail/'+postData.slug, postData,'get');
  }

  getBlogsList(postData: any) {
    return this.postReq('allBlogs', postData,'get');
  }

  getVendorList(postData: any) {
    return this.postReq('vendorList/'+postData.category+'/'+postData.state+'/'+postData.city+'/'+postData.price+'/'+postData.rating+'/'+postData.vendor_name+'/'+postData.page, postData,'get');
  }

  getVenueList(postData: any) {
    return this.postReq('venueList/'+postData.venue_for+'/'+postData.category+'/'+postData.state+'/'+postData.city+'/'+postData.capacity+'/'+postData.price+'/'+postData.rating+'/'+postData.vendor_name+'/'+postData.page, postData,'get');
  }

  getVender(postData: any) {
    return this.postReq('vendor_detail/'+postData.category+'/'+postData.slug+'/'+postData.city, postData,'get');
  }

  getSimilarVender(postData: any) {
    return this.postReq('getSimilarVender/'+postData.d_c_id+'/'+postData.city_id, postData,'get');
  }

  getVenue(postData: any) {
    return this.postReq('venue_detail/'+postData.category+'/'+postData.slug+'/'+postData.city, postData,'get');
  }

  getSimilarVenue(postData: any) {
    return this.postReq('getSimilarVenue/'+postData.p_c_id+'/'+postData.city_id, postData,'get');
  }

  getMetaTag(postData: any) {
    return this.postReq('getMetaData/'+postData.page+'/'+postData.category+'/'+postData.city+'/'+postData.slug, postData,'get');
  }


  customerLogin(postData: any) {
    return this.postReq('login', postData,'post');
  }

  saveRatingReview(postData: any) {
    return this.postReq('save_rating', postData,'post');
  }



}




